<template>
  <div class="my-order">
    <div class="user-panel-title-box">
      <h2>Orders</h2>
      <!--        <h6 style="margin-top: 4px">We offer the following payment methods</h6>-->

      <div>

      </div>
      <div class="row mb-2 mt-2" style="margin-left:2px;" v-if="this.bound !== null && this.boundMsg !== ''">
        <div class="alert alert-danger d-flex align-items-center" role="alert">
          <img style="width:30px; margin-right:10px;" src="@/images/tmp/alert.png" alt="alert">
          <div>
            {{ this.boundMsg }}
          </div>
        </div>
      </div>
    </div><!-- end user-panel-title-box -->

    <div class="profile-setting-panel-wrap" v-loading="loading">
      <div class="table-responsive">
        <table class="table mb-0 table-s2">
          <thead class="fs-14">
          <tr>
            <th scope="col" class="text-center" v-for="(list, i) in tableHead" :key="i">{{ list }}</th>
          </tr>
          </thead>
          <tbody class="table-fs">
          <tr v-for="it in records" :key="it.id">
            <!--            <th scope="row"><a href="#">{{ it.id }}</a></th>-->
            <td class="text-center">
              <span class="badge text-bg-danger " v-if="it.status === 0"> Expire </span>
              <span class="badge text-bg-dark " v-if="it.status ===-100"> Cancel </span>
              <span class="badge text-bg-warning" v-if="it.status ===-1">
                Inactive
              </span>
              <span class="badge text-bg-success" v-if="it.status === 1">
                Active
              </span>

<!--              <p style="font-weight: 500;font-size: 12px;">Time left: {{ countdown[it.id]}}</p>-->
            </td>
<!--            <td class="text-center small">{{ countdown[it.id]}}</td>-->
            <td class="text-center" style="font-weight: 500;font-size: 16px;">
              ${{ it.amount }}&nbsp;/ {{ getDays(it) }} {{ getDays(it) === '1' ? 'Day' : 'Days' }}
              <br/>
              {{ it.plan.name }}

            </td>

            <td class="text-center small">{{ it.quantity }}</td>
            <td class="text-center small">${{ it.amount }}</td>
            <td class="text-center small">${{ it.netProfit }}</td>
            <td class="text-center small">{{ getTime(it.createdAt) }}</td>
            <td class="text-center small" v-if="it.status!==-1 && it.status!== -100">
<!--              {{ getTime(it.endTime) }}-->
              <div class="progress-box">
                <div class="progress" role="progressbar" aria-label="Countdown Progress"
                     :aria-valuenow="progressValues[it.id]" aria-valuemin="0" aria-valuemax="100">
                  <div class="progress-bar progress-bar-striped progress-bar-animated"
                       :class="[progressValues[it.id] === 100 ? 'bar-color_over' : 'bar-color_proceed']"
                       :style="{width: progressValues[it.id] + '%'}">
                    {{ progressValues[it.id] }}%
                  </div>
                </div>
              </div>
              <span class="d-block" style="color: red;">{{ countdown[it.id] }} </span>
            </td>
            <td class="text-center small" v-if="it.status===-1">
              <span class="badge text-bg-warning">
                Inactive
              </span>
            </td>
            <td class="text-center small" v-if="it.status===-100">
              <span class="badge text-bg-dark">
                Cancel
              </span>
            </td>
          </tr>
          </tbody>
        </table>
      </div><!-- end table-responsive -->
      <!-- pagination -->
      <div class="text-center mt-4 mt-md-5">
        <Pagination :records="totalCount" v-model="transForm.pageNum" :per-page="transForm.pageSize"></Pagination>
      </div>
    </div><!-- end profile-setting-panel-wrap-->

  </div>
</template>

<script>
import planApi from "@/apis/planApi";
import date from "@/date";
import Pagination from "v-pagination-3";

export default {
  name: "MyOrder",
  components: {
    Pagination
  },
  data() {
    return {
      bound: undefined,
      boundMsg: '',
      loading: false,
      records: [],
      transForm: {
        pageNum: 1,
        pageSize: 5
      },
      totalCount: 0,
      totalPages: 1,
      tableHead: [
         'Status', 'Contract', 'Quantity', 'Amount', 'Net Profit','Order Time', 'Remain Time'
      ],
      countdown:  {},
      progressValues: {}, // Store progress values for each item
      timer: null, // Store interval timer reference
    }
  },
  watch: {
    'transForm.pageNum': function () {
      this.getTable()
    }
  },
  mounted() {
    this.getTable()
    this.timer = setInterval(() => this.updateCountdown(), 1000);
  },
  unmounted() {
    clearInterval(this.timer);
  },
  methods: {
    getTable() {
      this.loading = true;
      planApi.mine(this.transForm, res => {
        this.loading = false;
        console.log(res)
        this.bound = res.data.bound
        if (this.bound) {
          this.boundMsg = 'Inactive Status: '+this.bound.msg
        }
        this.records = res.data?.page.result || [];
        this.totalCount = res.data?.page.totalCount || 0;
        this.totalPages = res.data?.page.totalPages || 1;
        // if (this.trans.result.length <= this.transForm.pageSize) this.transLoad = -1
      })
    },
    getDate(data) {
      return date.getDate(data)
    },
    getTime(data) {
      return date.getTime(data)
    },
    search() {
      this.transForm.pageNum = 1;
      this.totalCount = 0;
      this.totalPages = 0;
      this.records = [];
      this.getTable()
    },
    getDays(ob) {
      const mistiming = this.formatDateTimeSecond(ob)
      if (mistiming > 0) {
        var days = Math.floor(mistiming / 1000 / 60 / 60 / 24)
      }
      return `${days}`
    },

    formatDateTimeSecond(ob) {
      const start_date = ob.startTime
      const end_date = ob.endTime
      if (start_date === '' || !start_date) {
        return ''
      }
      if (end_date === '' || !end_date) {
        return ''
      }
      const date1 = new Date(start_date);
      const date2 = new Date(end_date);
      return date2.getTime() - date1.getTime()
    },
    updateCountdown() {
      const utcCurrentTime = new Date().getTime();

      if (!this.records || this.records.length === 0) {
        return;
      }
      this.records.forEach(it => {
        const endTime = new Date(it.endTime).getTime();
        const startTime = new Date(it.startTime).getTime();
        const totalDuration = endTime - startTime;
        const timeDiff = endTime - utcCurrentTime;

        if (timeDiff > 0) {
          const totalSeconds = Math.floor(timeDiff / 1000);
          const days = Math.floor(totalSeconds / (60 * 60 * 24));
          const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
          const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
          const seconds = totalSeconds % 60;
          const formattedHours = hours.toString().padStart(2, '0');
          const formattedMinutes = minutes.toString().padStart(2, '0');
          const formattedSeconds = seconds.toString().padStart(2, '0');
          const formattedTime = `${days} Day(s) ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
          this.countdown[it.id] = formattedTime;
        } else {
          this.countdown[it.id] = 'Expired';
        }
        // Calculate progress percentage
        if (totalDuration > 0) {
          const progressPercentage = Math.min(100, (1 - timeDiff / totalDuration) * 100);
          this.progressValues[it.id] = Math.max(0, progressPercentage.toFixed(2)); // Update progress
        } else {
          this.progressValues[it.id] = 100; // If no time left, set progress to 100%
        }
      });
    },
  }

}
</script>

<style scoped lang="scss">
.table-fs {
  font-size: 0.975rem;
}


.my-order {
  .item {
    //width: 338px;
    //height: 264px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    transition: all 0.3s ease;
    //cursor: pointer;
    &:hover {
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
    }

    &-top {
      display: flex;
      align-items: center;
      height: 80px;
      padding: 0 15px;
      position: relative;

      &::after {
        content: "";
        bottom: -0.5px;
        left: 15px;
        right: 15px;
        height: 1px;
        opacity: 0.13;
        background-color: #979797;
        position: absolute;
      }

      img {
        width: 83px;
        margin-right: 5px;
      }

      div {
        flex: 1;
        width: 0;
        font-size: 16px;
        //font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #1C2A46;
        line-height: 22px;
      }
    }

    &-content {
      position: relative;
      padding: 0 15px;

      .value {
        padding: 10px 0;
        font-size: 14px;
        //font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #1C2A46;
        line-height: 20px;

        .p1 {

        }

        .p2 {
          margin-top: 4px;
        }
      }

      &::after {
        content: "";
        bottom: -0.5px;
        left: 15px;
        right: 15px;
        height: 1px;
        background-color: #979797;
        opacity: 0.13;
        position: absolute;
      }
    }

    &-footer {
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 15px;
    }
  }
}

.bar-color_over {
  background-color: #fe4d59 !important; /* Ensure this is applied */
}
</style>
